@import '../../theme/variables.scss';

.container_forgetpass {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f8fb;
  height: 100vh;

  .form_container {
    margin-top: 66.9px;
    width: 100%;
    border-radius: 15px;
    border-radius: 2px;
    padding: 0px 20px;
  }

  .logo {
    margin-top: 68px;
    width: 150px;
    height: 101.1px;
  }
  @media (min-width: 908px) {
    .form_container {
      width: 800px;
    }
  }
}
