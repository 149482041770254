.container {
  padding-left: 15px;
  padding-bottom: 18px;
}

.productContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.productsTitle {
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.leftColumn {
  width: 30%;
}

.leftColumn .img {
  width: 100%;
  object-fit: contain;
}

.rightColumn {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.removeButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.removeButton :disabled {
  background-color: transparent;
  border: none;
  outline: none;
}

.orderNumber {
  font-size: 18px;
  font-weight: 500;
}

.input {
  width: 100%;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 11px;
  font-size: 14px;
  margin-top: 10px;
}

.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.addProducts {
  font-size: 15px;
  font-weight: 300;
  color: #4f4f5a;
}

.subTitle {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #4f4f5a;
}

.data {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 3rem;
  color: #4f4f5a;
}

.bottomContainer {
  position: sticky;
  bottom: 0;
  background: #fff;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.confirmButton {
  background-color: #00b1c5;
  color: white;
  margin-left: 10px;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

.cancelButton {
  background-color: #cacacd;
  color: #83838b;
  margin-right: 10px;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 15px 0;
  margin-top: 50px;
}
