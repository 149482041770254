.container {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 1rem;
  background-color: #f7f8fb;
  border-radius: 0.5rem;
  padding: 3rem;
  justify-content: space-between;
}

.img {
  width: 100%;
  object-fit: contain;
}

.rightContainer {
  width: 100%;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.leftContainer {
  width: 30%;
}

.productName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.subTitle {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #4f4f5a;
}

.data {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0.2rem;
  color: #4f4f5a;
}

.buttonsContainer {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: justify-between;
}

.confirm {
  background-color: #52c41a;
  color: white;
  border-radius: 0.3rem;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-left: 15px;
}

.confirm:hover {
  background-color: #52c41a;
  color: white;
  border-color: #52c41a;
  transform: scale(1.05);
}

.cancel {
  background-color: red;
  color: white;
  border-radius: 0.3rem;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-right: 15px;
}

.cancel:hover {
  background-color: red;
  color: white;
  border-color: red;
  transform: scale(1.05);
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 1rem;
}
