@import '../../theme/variables.scss';

.container_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #364156;
  height: 100vh;
  .logo {
    margin-top: 68px;
    width: 150px;
    height: 101.1px;
  }
  .form_paper {
    width: 100%;
    border-radius: 15px;
    border-radius: 2px;
    padding: 0px 20px;
    margin-top: 66.9px;
  }

  @media (min-width: 908px) {
    .form_paper {
      width: 800px;
    }
  }
  .button_forgot {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #2f95af;
    margin-top: 28px;
    .span_password {
      font-weight: bold;
      padding-left: 5px;
    }
  }
}
