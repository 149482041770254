@import '../../theme/variables.scss';

.successView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      font-size: 70px;
    }

    .message {
      color: $primary-color;
      font-weight: 600;
      font-size: 24px;
      margin: 15px 0;
    }

    .card {
      width: 100%;
      border: 1px solid $grey;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      padding: 10px;

      .col {
        p {
          margin-bottom: 5px;
        }
      }

      .col2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 0;

        p {
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
    }

    .newOrderButton {
      width: 100%;
      margin-top: 15px;
    }
  }
}

.noEdit {
  width: 350px;
  border-radius: 6px;
  border: 1px solid $grey;
  padding: 10px;
  margin-top: 20px;

  p {
    font-weight: 500;
  }

  &__noMarginTop {
    margin-top: 0;
  }
}

.titles {
  font-size: 18px;
  font-weight: bold;
}

.titles2 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
}

.billsContainer {
  width: 90%;
  max-height: 180px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-bottom: 1px solid $grey;
  padding-bottom: 20px;
}

.fullInput {
  width: 100%;

  input {
    padding: 4px 11px;
  }
}

.halfInput {
  width: 100%;
  box-sizing: border-box;

  &:nth-child(1) {
    margin-right: 20px;
  }
}

.doubleInputContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerForRadio {
  display: flex;
  justify-content: center;
  border-top: #f0f0f0 1px solid;
  margin-top: 15px;
  padding: 12px 0;
}

.displayNone {
  display: none;
}

.appearingAnimation {
  animation-name: appearinganim;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;

  @keyframes appearinganim {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }
}

.billingDataSaved {
  color: $primary-color;
  margin-left: 10px;
}

.paymentMethodsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .section {
    width: 50%;
    max-width: 50%;
    margin-top: 20px;

    .cash {
      .inputCont {
        width: 100%;
        margin-bottom: 15px;
      }

      .buttonsContainer {
        display: flex;
        flex-direction: column;
        width: 120px;

        .confirmButton {
          &__accept {
            margin-top: 10px;
          }
        }
      }

      .leftButton {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .billsCards {
      width: 70%;
      border: 1px solid $primary-color;
      border-radius: 4px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-top: 10px;

      p {
        margin-bottom: 0;
        margin: 0 5px;
      }

      .deleteButton {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
    }
  }
}
