@import 'theme/variables.scss';

.cardContainer {
  .cardChild {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.headerContainer {
  display: 'flex';
  justify-content: 'space-between';
}
