@import 'theme/variables.scss';

.base {
  position: relative;
  border: 0;
  border-radius: 2px;
  width: 100%;
  outline: 0;
  padding: 0.7em 0em;
  overflow: hidden;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;

  span {
    position: absolute;
    background: $secondary-color;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ripple 0.7s linear infinite;
  }

  &.small {
    width: 120px;
    height: 45px;
    padding: 0;
    font-size: 15px;
  }

  &.medium {
    width: 45%;
  }

  &.large {
    width: 80%;
  }

  &.primary {
    background-color: $primary-color;
    color: $secondary-color;
  }

  &.secondary {
    background-color: $secondary-color;
    color: $primary-color;
    border: 1px solid $primary-color;

    span {
      background: $primary-color;
    }
  }

  &:disabled {
    background-color: $secondary-color;
    color: $grey;
    border: 1px solid $grey;
  }
}

@keyframes ripple {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
