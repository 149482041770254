.header {
  display: flex;
  .routeName {
    align-self: flex-end;
    margin-right: 10px;
    font-weight: bold;
  }
}

.name {
  font-size: large;
}

.title {
  width: 50%;
}

.value {
  width: 50%;
  font-weight: normal;
}

.proco {
  font-size: medium;
  font-weight: normal;
}
