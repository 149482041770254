@import 'theme/variables.scss';

.banner_preview_container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .imageContainer {
    width: 900px;
  }
  .cardContainer {
    width: auto;
    border-radius: 15px;
    text-align: center;
  }
}
