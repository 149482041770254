@import 'theme/variables.scss';

.logo {
  display: flex;
  margin: 20px auto;
  width: 90%;
  height: 101.1px;
}

.header {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}

.alerts {
  width: 32px;
  height: 32px;
}

.icon {
  transition: 0.5s;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  filter: invert(57%) sepia(12%) saturate(382%) hue-rotate(182deg)
    brightness(92%) contrast(92%) transition 0.5s;
}

.footer_container {
  text-align: center;
}

.logo_footer {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px;
  height: 101.1px;
}

.container {
  .menu {
    display: flex;
    align-items: center;
    .opacity {
      opacity: 0;
      transition: 0.5s;
    }
  }
  .menu:hover {
    .icon {
      filter: brightness(0) invert(1);
    }
  }

  .icon_light {
    padding-right: 10px;
    filter: brightness(0) invert(1);
  }

  .paddingContent {
    min-height: 360px;
    @media (min-width: 800px) {
      padding: 30px;
    }
  }
  .paddingMovil {
    @media (max-width: 799px) {
      padding: 30px;
    }
  }

  .originalHeight {
    min-height: 100vh;
  }

  .userHeightSide {
    height: 100vh;
  }

  .keepWidth {
    width: 1000px;
    height: 2350px;
    @media (min-width: 320px) {
      height: 2200px;
    }
    @media (min-width: 769px) {
      width: 100%;
      height: 100%;
    }
  }

  .roleStyles {
    width: 600px;
    min-height: 100vh;
    @media (min-width: 600px) {
      width: 100%;
    }
  }

  .ant-layout-sider-trigger {
    position: inherit;
  }
}
