@import 'theme/variables.scss';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
