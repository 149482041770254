@import 'theme/variables.scss';

.buttonContainer {
  div {
    display: flex;
    flex-direction: column;
  }
  button {
    margin: 0.5rem;
  }
}
