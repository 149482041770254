.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .m_bottom_1 {
    margin-bottom: 1em;
  }

  .actions_button {
    margin-top: 8px;
    width: 45%;
    justify-content: space-around;
    display: flex;
  }
}