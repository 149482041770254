@import '../../theme/variables.scss';


.container {
  position: relative;

  .loading {
    width: 100%;
    height: 57%;
    background-color: #9a9a9a5f;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
  }

  p {
    margin-bottom: 0;
  }
}