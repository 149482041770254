.form_login_container {
  .ant-row {
    margin: 0 auto;
  }

  .form_item {
    display: flex;
    justify-content: center;

    [class*="ant-form-item-control"] {
      width: 316px;
    }

    .input_icon {
      margin-right: 5px;
    }

    .input {
      padding: 12px;
      border-width: 0px;
      height: 48px;
    }

    .button_login {
      padding: 12px;
      width: 100%;
      height: 46px;
      background: #2fc9af;
      border: 1px solid #2fc9af;
      box-sizing: border-box;
      border-radius: 10px;
    }
  }
}