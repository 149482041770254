@import 'theme/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formPaper {
  width: 800px;
  border-radius: 15px;
  border-radius: 2px;
  border: 1px solid $light-grey;
  padding: 20px 20px;
  margin-top: 5%;
}
