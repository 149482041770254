//primary colors
$primary-color: #1890ff;
$secondary-color: #ffffff;

//colors
$white: #ffffff;
$grey: #d1d1fb;
$light-grey: #e6ebf1;

:export {
  primary-color: $primary-color;
  secondary-color: $secondary-color;
  white: $white;
  grey: $grey;
  light-grey: $light-grey;
}
